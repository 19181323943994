import { Stack } from "@pomebile/primitives"

interface RadioButtonProps {
  isSelected: boolean
}

export const RadioButton = ({ isSelected }: RadioButtonProps) => {
  return (
    <Stack
      border={["thick", "border-button-primary", "solid"]}
      borderRadius="lg"
      padding="xs2"
      width="fitContent"
    >
      <Stack
        fill={isSelected ? "background-button-primary" : "background-default"}
        height="sm"
        width="sm"
        borderRadius="lg"
      />
    </Stack>
  )
}
