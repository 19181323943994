import { Svg, Path } from "@pomebile/primitives"
import { IconProps } from "./icon-contract"

export const HourglassIcon = ({ width, height, fill }: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" customFill="none">
      <Path
        d="M18.75 7.09125V3.75C18.75 3.35218 18.592 2.97064 18.3107 2.68934C18.0294 2.40804 17.6478 2.25 17.25 2.25H6.75C6.35218 2.25 5.97064 2.40804 5.68934 2.68934C5.40804 2.97064 5.25 3.35218 5.25 3.75V7.125C5.25051 7.35778 5.30495 7.58727 5.40905 7.79548C5.51315 8.00368 5.66408 8.18493 5.85 8.325L10.7503 12L5.85 15.675C5.66408 15.8151 5.51315 15.9963 5.40905 16.2045C5.30495 16.4127 5.25051 16.6422 5.25 16.875V20.25C5.25 20.6478 5.40804 21.0294 5.68934 21.3107C5.97064 21.592 6.35218 21.75 6.75 21.75H17.25C17.6478 21.75 18.0294 21.592 18.3107 21.3107C18.592 21.0294 18.75 20.6478 18.75 20.25V16.9088C18.7494 16.677 18.6954 16.4485 18.5921 16.2411C18.4889 16.0336 18.3392 15.8527 18.1547 15.7125L13.2441 12L18.1547 8.2875C18.3393 8.14742 18.4891 7.96658 18.5924 7.75908C18.6957 7.55158 18.7496 7.32303 18.75 7.09125ZM16.7091 16.5H7.24969L12 12.9375L16.7091 16.5ZM6.75 20.25V18H17.25V20.25H6.75ZM17.25 7.09125L12 11.0625L6.75 7.125V3.75H17.25V7.09125Z"
        fill={fill}
      />
    </Svg>
  )
}

export const HourglassCalloutIcon = () => (
  <HourglassIcon width={44} height={44} fill="icon-inverse" />
)
