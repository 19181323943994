import { PomeloCardIcon, ActivityIcon } from "@pomebile/design-system"
import { Txt, VStack, Button } from "@pomebile/primitives"
import { StickyBottom } from "../components/StickyBottom"
import { ScreenFrame } from "../components/ScreenFrame"
import { AppDownloadQRForDesktop } from "../components/AppDownloadQRForDesktop"
import { onlyShowOnMobileStyles } from "../sharedStyles.css"
import { deeplink } from "../utils/deeplink"
import { MtpRatePromo } from "../api/webRoutes"
import { FxPromoBanner } from "../components/FxPromoBanner"
import { useFeatureFlag } from "../utils/featureFlag.tsx"

interface IconItemProps {
  icon: JSX.Element
  text?: undefined
  title: string
  subtitle: string
}

interface TextItemProps {
  text: JSX.Element
  icon?: undefined
  title: string
  subtitle: string
}

type ItemProps = IconItemProps | TextItemProps

export type ProductTypes = "unsecured" | "secured"

interface CompleteProps {
  mtpRatePromo?: MtpRatePromo
  productType: ProductTypes
}

export function Complete({ mtpRatePromo, productType }: CompleteProps) {
  const { enabledFlags } = useFeatureFlag()
  const displayPromoExpiry = enabledFlags.includes("DISPLAY_PROMO_EXPIRY")

  const handleDownloadPress = () => {
    window.open(deeplink.OnboardingV2DownloadLink.link, "_blank")
  }

  const rowData: ItemProps[] = []

  const moneyTransferPromo = mtpRatePromo
  if (moneyTransferPromo) {
    rowData.push({
      text: (
        <Txt variant="headline3" as="span">
          ₱{moneyTransferPromo.rate}
        </Txt>
      ),
      subtitle: "Promotional exchange rate lasts for a limited time only.",
      title: `Claim your $1 = ₱${moneyTransferPromo.rate} Promo`,
    })
  }

  rowData.push(
    {
      icon: <PomeloCardIcon />,
      subtitle: "Once activated, use it to spend anywhere Mastercard is accepted.",
      title: "Activate your Pomelo Mastercard®",
    },
    {
      icon: <ActivityIcon />,
      subtitle: "Stay on top of all your transactions and payments.",
      title: "Track transactions and payments",
    },
  )

  return (
    <ScreenFrame>
      <VStack gap="xl3" justifyContent="space-between" height="full">
        <VStack gap="xl2" justifyContent="center" alignItems="center">
          <VStack alignItems="center">
            <Txt variant="headline2" as="h1" textAlign="center">
              {displayPromoExpiry
                ? "Download the Pomelo app to claim your promo"
                : "Now, let's download Pomelo to send money"
              }
            </Txt>
            <VStack padding={{ top: "xs" }}>
              <Txt as="p" variant="body1" textAlign="center">
                {productType === "unsecured"
                  ? "Use your new Pomelo Mastercard® to send money now and pay later in the Pomelo app."
                  : "Use your debit card to send money in the Pomelo app."}
              </Txt>
            </VStack>
          </VStack>
          {moneyTransferPromo && (
            <VStack height="full">
              <FxPromoBanner
                caption={
                  displayPromoExpiry
                    ? "Promotional rate applies to the first transaction up to $500 sent by new customers within 7 days."
                    : "Promotional rate applies to the first transaction up to $500 sent by new customers."
                }
                promoRate={moneyTransferPromo.rate}
              />
            </VStack>
          )}
          <AppDownloadQRForDesktop type="OnboardingV2DownloadLink" />
        </VStack>
        <div className={onlyShowOnMobileStyles}>
          <StickyBottom>
            <Button onClick={handleDownloadPress}>Download Pomelo App</Button>
          </StickyBottom>
        </div>
      </VStack>
    </ScreenFrame>
  )
}
