import {
  ChevronIcon,
  PHFlagRectIcon,
  PomeloCardIcon,
  Callout,
  PHFlagIcon, VSpace,
} from "@pomebile/design-system"
import { Avatar, HStack, Link, Txt, VStack } from "@pomebile/primitives"
import { sprinkles } from "@pomebile/primitives-web"
import { StickyBottom } from "../../components/StickyBottom"
import { RatesAndTermsDialog } from "./RatesAndTermsDialog"
import { useEffect, useReducer } from "react"
import { MtpRatePromo, NewAccountResponse } from "../../api/webRoutes"
import { useSubmit } from "../../hooks/useSubmit"
import { SubmitButton } from "../../components/Form/FormSubmitButton"
import { ScreenFrame } from "../../components/ScreenFrame"
import { TermsAndConditionsDialog } from "../../components/TermsAndConditionsDialog"
import { DeclineDialog } from "../UnsecuredOffer/DeclineDialog"
import { DeclineReasonDialog } from "../DeclineReasonDialog"
import { UserResponse } from "@pomebile/pomelo-service-api"
import { OfferDetailItemRow } from "../../components/OfferDetailItemRow.tsx"
import { useFeatureFlag } from "../../utils/featureFlag.tsx"
import { createLoggingContext } from "../../sharedShellLogic.tsx"

type DialogKind =
  | "ratesAndTermsDialog"
  | "termsAndConditionsDialog"
  | "declineDialog"
  | "declineReasonDialog"

type Ev = {
  action: "open" | "close"
  dialog: DialogKind
}
type DialogState = {
  dialog: DialogKind | undefined // undefined expresses that no dialog is opened
  prevDialog: DialogKind | undefined
}

const updateDialogState = (prevState: DialogState, ev: Ev): DialogState => {
  const { action, dialog } = ev

  if (action === "open") {
    return { prevDialog: prevState.dialog, dialog }
  }

  if (prevState.dialog !== undefined && action === "close" && dialog === prevState.dialog) {
    if (dialog === "termsAndConditionsDialog") {
      return { ...prevState, dialog: prevState.prevDialog }
    }

    return { ...prevState, dialog: undefined }
  }

  return prevState
}

type AcceptResult =
  | {
  tag: "accepted"
  productGroupIdent: string
  updatedMtpRatePromo?: MtpRatePromo
}
  | {
  tag: "declined"
}
  | {
  tag: "noProductGroupIdent"
}

export interface SecuredOfferProps {
  api: {
    openAccount: () => Promise<NewAccountResponse>
    declineReason: (reason: string) => Promise<UserResponse>
  }
  mtpRatePromo?: MtpRatePromo
  onDone: (result: AcceptResult) => void
}

export const SecuredOffer = ({ api, mtpRatePromo, onDone }: SecuredOfferProps) => {
  const { logEvent } = createLoggingContext()
  const { isLoading, enabledFlags } = useFeatureFlag()
  const displayPromoExpiry = enabledFlags.includes("DISPLAY_PROMO_EXPIRY")

  useEffect(() => {
    if (isLoading) {
      return
    }
    logEvent("Viewed Secured Offer", { displayPromoExpiry })
  }, [displayPromoExpiry, logEvent, isLoading])

  const [{ dialog }, send] = useReducer(updateDialogState, {
    dialog: undefined,
    prevDialog: undefined,
  })
  const [submit, status] = useSubmit(async () => {
    try {
      const { productGroupIdent, updatedMtpRatePromo } = await api.openAccount()
      onDone({
        tag: "accepted",
        productGroupIdent,
        updatedMtpRatePromo,
      })
    } catch (ResponseValidationError) {
      onDone({
        tag: "noProductGroupIdent",
      })
      return
    }
  })

  const openDialog = (dialog: DialogKind) => {
    send({ action: "open", dialog })
  }

  const closeDialog = (dialog: DialogKind) => {
    send({ action: "close", dialog })
  }


  const ratesAndFeesLink = (
    <Link as="div" onClick={() => openDialog("ratesAndTermsDialog")}>
      <HStack justifyContent="center" alignItems="center">
        <Txt variant="button2" color="text-brand">
          View Rates and Terms
        </Txt>
        <ChevronIcon fill="icon-brand" direction="right" />
      </HStack>
    </Link>
  )

  const moneyTransferPromo = mtpRatePromo

  const promoDetailCallout = (
    moneyTransferPromo && <VStack width="full">
      <Callout variant="success">
        <VStack alignItems="center" gap="xs2">
          <HStack alignItems="center" width="unset">
            <Txt color="text-default" variant="subtitle2">
              $1 =&nbsp;
            </Txt>
            <div className={sprinkles({ overflow: "hidden", borderRadius: "xs" })}>
              <PHFlagRectIcon width={16} height={12} />
            </div>
            <Txt color="text-default" variant="subtitle2">
              &nbsp;₱{moneyTransferPromo.rate}
            </Txt>
          </HStack>
          <VStack alignItems="center">
            <Txt color="text-default" variant="caption" textAlign="center">
              For your first transfer up to ${moneyTransferPromo.limit}
              {displayPromoExpiry && " within 7 days of accepting this plan."}
            </Txt>
            <Txt variant="caption">
              <Link
                inline
                color="neutral"
                decoration="underline"
                onClick={() => openDialog("termsAndConditionsDialog")}
              >
                Terms and Conditions
              </Link>
            </Txt>
          </VStack>
        </VStack>
      </Callout>
    </VStack>
  )

  return (
    <ScreenFrame>
      <VStack justifyContent="space-between">
        <VStack height="full" gap="xl2" padding={{ bottom: "xl" }}>
          <VStack>
            <Txt variant="subtitle2" color="text-emphasis" textAlign="center">
              You're Approved
            </Txt>
            <Txt variant="headline2" textAlign="center">
              Pomelo Credit Builder
            </Txt>
            <VSpace height="xs" />
            <Txt variant="body2" textAlign="center">
              Pomelo Credit Builder can help you build your credit while sending money to the
              Philippines.
            </Txt>
          </VStack>

          <VStack gap="lg">
            {moneyTransferPromo && (
              <OfferDetailItemRow
                banner={displayPromoExpiry ? "Limited Time Promotion" : undefined}
                icon={<PHFlagIcon width={24} height={24} />}
                title="Send money to the Philippines"
                subtitle="Take advantage of zero transfer fees when you send to GCash or Bank Accounts."
                bottomElement={promoDetailCallout}
              />
            )}
            <OfferDetailItemRow
              icon={<PomeloCardIcon />}
              title="Get a Pomelo Secured Mastercard® to help build credit"
              subtitle="Based on your application and credit history, you’ve been approved for a Pomelo Secured Mastercard®️. Get your card by accepting this plan and downloading the Pomelo app to make a security deposit."
              bottomElement={ratesAndFeesLink}
            />
            <Txt variant="caption" color="text-caption" textAlign="center">
              Accepting the offer and funding the security deposit will not impact your credit
              score.
            </Txt>
          </VStack>
        </VStack>

        <StickyBottom>
          <VStack gap="sm">
            <SubmitButton submit={submit} status={status}>
              Accept Plan
            </SubmitButton>
            <Link onClick={status === "idle" ? () => openDialog("declineReasonDialog") : undefined}>
              <Txt textAlign="center" color="text-brand" as="p">
                Decline
              </Txt>
            </Link>
          </VStack>
        </StickyBottom>

        <RatesAndTermsDialog
          isOpen={dialog === "ratesAndTermsDialog"}
          onClose={() => closeDialog("ratesAndTermsDialog")}
          onOpenTermsAndConditions={() => openDialog("termsAndConditionsDialog")}
        />

        <TermsAndConditionsDialog
          open={dialog === "termsAndConditionsDialog"}
          showMoneyTransferPromo={!!moneyTransferPromo}
          onClose={() => closeDialog("termsAndConditionsDialog")}
        />

        <DeclineReasonDialog
          isOpen={dialog === "declineReasonDialog"}
          onClose={() => closeDialog("declineReasonDialog")}
          onDecline={() => {
            openDialog("declineDialog")
          }}
          api={api.declineReason}
        />

        <DeclineDialog
          isOpen={dialog === "declineDialog"}
          onClose={() => closeDialog("declineDialog")}
          onDecline={() => {
            onDone({ tag: "declined" })
          }}
        />
      </VStack>
    </ScreenFrame>
  )
}
