import { IdentifierType, SsnOrItinOutcome } from "../api/webRoutes.tsx"
import { useFeatureFlag } from "../utils/featureFlag.tsx"
import { VerifyIdentitySsnOrItin } from "./VerifyIdentitySsnOrItin.tsx"
import { createLoggingContext } from "../sharedShellLogic.tsx"
import { useEffect } from "react"
import { VerifyIdentitySsn } from "./VerifyIdentitySsn.tsx"

export type VerifyIdentityProps = {
  api: {
    submitDateOfBirth: (dateOfBirth: [year: number, day: number, month: number]) => Promise<void>
    submitSsnOrItin: (
      ssnOrItin: string,
      identifierType: IdentifierType,
    ) => Promise<SsnOrItinOutcome>
  }
  onDone: (result: SsnOrItinOutcome) => void
}

export function VerifyIdentity(props: VerifyIdentityProps) {
  const { isLoading, enabledFlags } = useFeatureFlag()
  const isItinEnabled = enabledFlags.includes("ENABLE_ITIN")
  const { logEvent } = createLoggingContext()

  useEffect(() => {
    if (isLoading) {
      return
    }
    logEvent("Viewed VerifyIdentity screen", { isItinEnabled })
  }, [isLoading, isItinEnabled, logEvent])

  return isItinEnabled ? (
    <VerifyIdentitySsnOrItin api={props.api} onDone={props.onDone} />
  ) : (
    <VerifyIdentitySsn api={props.api} onDone={props.onDone} />
  )
}
