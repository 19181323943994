import { Dialog } from "@headlessui/react"
import { PHFlagRectIcon, VSpace } from "@pomebile/design-system"
import { HStack, Txt, VStack } from "@pomebile/primitives"
import { Link, sprinkles } from "@pomebile/primitives-web"
import { useState } from "react"
import { TermsAndConditionsDialog } from "./TermsAndConditionsDialog"
import { formatDate } from "@pomebile/shared/helpers"
import { useFeatureFlag } from "../utils/featureFlag.tsx"

type FxPromoBannerProps = {
  caption: string
  promoRate: number
}

// This should probably be promoted to a shared space at some point
export function FxPromoBanner({ caption, promoRate }: FxPromoBannerProps) {
  const { enabledFlags } = useFeatureFlag()
  const displayPromoExpiry = enabledFlags.includes("DISPLAY_PROMO_EXPIRY")
  const [isTermsOpen, setIsTermsOpen] = useState<boolean>(false)

  const toggleTermsAndConditions = () => {
    setIsTermsOpen((isTermsOpen) => !isTermsOpen)
  }

  const date = new Date()
  date.setDate(date.getDate() + 7)
  const promoExpiryDate = formatDate(
    date.toDateString(),
    { month: "long", day: "numeric", year: "numeric" }
  )

  return (
    <VStack>
      {displayPromoExpiry
        ? <>
          <VStack
            fill="background-callout-success-lite"
            borderRadius={{ top: "sm" }}
            border={{
              widths: { top: "thin", left: "thin", right: "thin" },
              color: "border-main",
            }}
            alignItems="center"
            padding={{ x: "xl", y: "sm" }}
          >
            <Txt variant="button2" color="text-emphasis">
              Claim your promo by {promoExpiryDate}
            </Txt>
          </VStack>
          <VStack
            fill="background-default"
            borderRadius={{ bottom: "sm" }}
            border={{
              widths: { bottom: "thin", left: "thin", right: "thin" },
              color: "border-main",
            }}
            gap="xs"
            alignItems="center"
            padding={{ x: "lg", top: "md", bottom: "xl" }}
          >
            <HStack justifyContent="center" alignItems="center">
              <Txt as="h2" variant="headline3">
                $1 =&nbsp;
              </Txt>
              <div className={sprinkles({ overflow: "hidden", borderRadius: "xs" })}>
                <PHFlagRectIcon width={23} height={16} viewBox="0 0 23 16" />
              </div>
              <Txt as="h2" variant="headline3">
                &nbsp;₱{promoRate}
              </Txt>
            </HStack>
            <Txt variant="caption" textAlign="center">
              {caption}
            </Txt>
            <Txt as="caption" variant="caption" color="text-caption">
              <Link color="neutral" decoration="underline" onClick={() => toggleTermsAndConditions()}>
                See Terms and Conditions
              </Link>
            </Txt>
          </VStack>
        </>
        : <VStack
          fill="background-default"
          borderRadius="sm"
          border={["thin", "border-main", "solid"]}
          gap="xs"
          alignItems="center"
          padding={{ x: "lg", y: "xl" }}
        >
          <Txt as="caption" variant="overline2">
            new customer offer
          </Txt>
          <HStack justifyContent="center" alignItems="center">
            <Txt as="h2" variant="headline3">
              $1 =&nbsp;
            </Txt>
            <div className={sprinkles({ overflow: "hidden", borderRadius: "xs" })}>
              <PHFlagRectIcon width={23} height={16} viewBox="0 0 23 16" />
            </div>
            <Txt as="h2" variant="headline3">
              &nbsp;₱{promoRate}
            </Txt>
          </HStack>
          <Txt variant="caption" textAlign="center">
            {caption}
          </Txt>
          <Txt as="caption" variant="caption" color="text-caption">
            <Link color="neutral" decoration="underline" onClick={() => toggleTermsAndConditions()}>
              See Terms and Conditions
            </Link>
          </Txt>
        </VStack>
      }

      <Dialog open={isTermsOpen} onClose={toggleTermsAndConditions}>
        <TermsAndConditionsDialog
          open={isTermsOpen}
          showMoneyTransferPromo={false}
          onClose={toggleTermsAndConditions}
        />
      </Dialog>
    </VStack>
  )
}
