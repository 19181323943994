import { Svg, Path, G, Mask, ClipPath, Rect, Defs } from "@pomebile/primitives"
import { IconProps } from "./icon-contract"

export const PomeloMonochromeIcon = ({ width = 30, height = 36, fill }: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 27 32" customFill="none">
      <G clip-path="url(#clip0_4144_37378)">
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.22 5.19826H12.1973L14.2409 1.9609C15.0117 0.738376 16.341 0 17.7668 0H23.789L21.7465 3.23736C20.9745 4.45929 19.6464 5.19826 18.22 5.19826Z"
          fill={fill}
        />
        <Mask id="mask0_4144_37378" maskUnits="userSpaceOnUse" x={0} y={6} width={27} height={26}>
          <Path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.849609 6.33118H26.0465V32H0.849609V6.33118Z"
            customFill="white"
          />
        </Mask>
        <G mask="url(#mask0_4144_37378)">
          <Path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.448 8.22752C9.93603 8.22752 2.71083 15.5876 2.71083 19.1657C2.71083 22.7438 9.93603 30.1038 13.448 30.1038C16.9605 30.1038 24.1863 22.7438 24.1863 19.1657C24.1863 15.5876 16.9605 8.22752 13.448 8.22752ZM13.4477 32.0001C8.85608 32.0001 0.849609 23.8436 0.849609 19.1656C0.849609 14.4877 8.85608 6.33118 13.4477 6.33118C18.04 6.33118 26.0465 14.4877 26.0465 19.1656C26.0465 23.8436 18.04 32.0001 13.4477 32.0001Z"
            fill={fill}
          />
        </G>
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.4477 17.9061C12.1766 17.9061 9.77344 15.4575 9.77344 14.1632C9.77344 12.869 12.1766 10.4198 13.4477 10.4198C14.7182 10.4198 17.122 12.869 17.122 14.1632C17.122 15.4575 14.7182 17.9061 13.4477 17.9061Z"
          fill={fill}
        />
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.4477 27.9111C12.1766 27.9111 9.77344 25.4625 9.77344 24.1682C9.77344 22.874 12.1766 20.4248 13.4477 20.4248C14.7182 20.4248 17.122 22.874 17.122 24.1682C17.122 25.4625 14.7182 27.9111 13.4477 27.9111Z"
          fill={fill}
        />
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.53754 22.9084C7.26644 22.9084 4.86328 20.4598 4.86328 19.1656C4.86328 17.8707 7.26644 15.4227 8.53754 15.4227C9.80806 15.4227 12.2118 17.8707 12.2118 19.1656C12.2118 20.4598 9.80806 22.9084 8.53754 22.9084Z"
          fill={fill}
        />
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.3598 22.9084C17.0887 22.9084 14.6855 20.4598 14.6855 19.1656C14.6855 17.8707 17.0887 15.4227 18.3598 15.4227C19.6309 15.4227 22.0341 17.8707 22.0341 19.1656C22.0341 20.4598 19.6309 22.9084 18.3598 22.9084Z"
          fill={fill}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_4144_37378">
          <Rect width={25.1969} height={32} customFill="white" transform="translate(0.849609)" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export const PomeloMonochromeCalloutIcon = () => (
  <PomeloMonochromeIcon fill="icon-inverse" height={44} width={44 * (30 / 36)} />
)
