import { ExclamationIcon, TextField, TextFieldProps } from "@pomebile/design-system"
import { MailSuggestion } from "@zootools/email-spell-checker/dist/lib/types"
import { useState } from "react"
import emailSpellChecker from "@zootools/email-spell-checker"
import { HStack, Txt } from "@pomebile/primitives"

interface EmailFieldProps extends TextFieldProps {}

export const EmailField = ({
  label,
  type,
  disabled,
  onChange,
  onBlur,
  errorText,
  value,
}: EmailFieldProps) => {
  const [emailSuggestion, setEmailSuggestion] = useState<MailSuggestion | undefined>(undefined)

  const handleChange = (email: string) => {
    setEmailSuggestion(emailSpellChecker.run({ email }))
    onChange && onChange(email)
  }

  const handleEmailSuggestionClick = () => {
    if (emailSuggestion) {
      onChange && onChange(emailSuggestion?.full)
      setEmailSuggestion(undefined)
    }
  }

  return (
    <TextField
      label={label}
      type={type}
      disabled={disabled}
      value={value}
      errorText={errorText}
      customWarningText={
        emailSuggestion ? (
          <div onClick={handleEmailSuggestionClick}>
            <HStack gap="xs2" alignItems="center">
              <ExclamationIcon width={16} height={16} fill="icon-default" />
              <HStack>
                <Txt as="span" variant="caption">
                  Did you mean&nbsp;
                </Txt>
                <Txt as="span" variant="caption" color="text-emphasis">
                  {emailSuggestion.full}
                </Txt>
                <Txt as="span" variant="caption">
                  ?
                </Txt>
              </HStack>
            </HStack>
          </div>
        ) : undefined
      }
      onChange={handleChange}
      onBlur={onBlur}
    />
  )
}
