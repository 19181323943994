import { Avatar, Txt, VStack } from "@pomebile/primitives"
import { he } from "@faker-js/faker"

type OfferDetailItemRowProps = {
  banner?: string
  icon: JSX.Element
  title: string
  subtitle: string
  bottomElement?: JSX.Element
}

export function OfferDetailItemRow({ banner, icon, title, subtitle, bottomElement }: OfferDetailItemRowProps) {
  return (
    <VStack>
      {banner && (
        <VStack
          padding="sm"
          alignItems="center"
          borderRadius={{
            top: "sm",
          }}
          border={{
            widths: {
              top: "thin",
              right: "thin",
              left: "thin",
            },
            color: "border-main",
            style: "solid",
          }}
          fill="background-callout-success-lite"
        >
          <Txt variant="button2" color="text-emphasis">
            {banner}
          </Txt>
        </VStack>
      )}
      <VStack
        padding="lg"
        alignItems="center"
        gap="sm"
        borderRadius={{
          top: banner ? undefined : "sm",
          bottom: "sm",
        }}
        border={{
          widths: {
            top: banner ? undefined : "thin",
            right: "thin",
            bottom: "thin",
            left: "thin",
          },
          color: "border-main",
          style: "solid",
        }}
      >
        <Avatar size="lg" svg={icon} />
        <VStack gap="xs2">
          <Txt variant="subtitle2" color="text-default" textAlign="center">
            {title}
          </Txt>
          <Txt variant="body2" color="text-default" textAlign="center">
            {subtitle}
          </Txt>
        </VStack>
        {bottomElement}
      </VStack>
    </VStack>
  )
}
