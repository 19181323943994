import { Button, HStack, Link, Stack, Txt, VStack } from "@pomebile/primitives"
import { Dialog } from "../../components/Dialog"
import { DialogHeader } from "../../components/Dialog/DialogHeader"
import { CrossIcon } from "@pomebile/design-system"
import { USAddress } from "../../api/webRoutes"
import { useState } from "react"
import { RadioButton } from "../../components/RadioButton"
import { segmentAnalyticsLogger } from "../../utils/segment"

interface ConfirmAddressDialogProps {
  isOpen: boolean
  onConfirmAddress: (address: USAddress) => Promise<void>
  defaultAddress: USAddress
  suggestedAddress: USAddress
  onClose: () => void
}

const AddressRow = ({
  title,
  addressLine1,
  addressLine2,
  isSelected,
  onSelect,
}: {
  title: string
  addressLine1: string
  addressLine2: string
  isSelected: boolean
  onSelect: () => void
}) => {
  return (
    <div onClick={onSelect}>
      <VStack>
        <HStack
          justifyContent="space-between"
          alignItems="center"
          border={["thin", isSelected ? "border-input-focused" : "border-main", "solid"]}
          borderRadius="md"
          padding="md"
        >
          <HStack gap="xs" alignItems="center" flex={1}>
            <VStack gap="xs">
              <Txt variant="subtitle2">{title}</Txt>
              <Stack>
                <Txt variant="body1">{addressLine1}</Txt>
                <Txt variant="body1">{addressLine2}</Txt>
              </Stack>
            </VStack>
          </HStack>
          <RadioButton isSelected={isSelected} />
        </HStack>
      </VStack>
    </div>
  )
}

const formatAddress = (address: USAddress): { addressLine1: string; addressLine2: string } => {
  return {
    addressLine1: address.addressLineTwo
      ? `${address.addressLineOne} ${address.addressLineTwo}`
      : address.addressLineOne,
    addressLine2: `${address.city}, ${address.state} ${address.zip}`,
  }
}

export const ConfirmAddressDialog = ({
  isOpen,
  onClose,
  onConfirmAddress,
  defaultAddress,
  suggestedAddress,
}: ConfirmAddressDialogProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState<"default" | "suggested">("default")
  const formattedDefaultAddress = formatAddress(defaultAddress)
  const formattedSuggestedAddress = formatAddress(suggestedAddress)

  const handleOnClose = () => {
    if (isLoading) return

    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={handleOnClose}>
      <DialogHeader>
        <HStack gap="xs" alignItems="center">
          <Txt variant="headline3">Confirm your address</Txt>
        </HStack>
        <HStack width="auto" justifyContent="flex-end" alignItems="center">
          <Link onClick={handleOnClose}>
            {/* Link adds unecessary height so we start at flex end */}
            <VStack justifyContent="flex-end">
              <CrossIcon width={24} height={24} />
            </VStack>
          </Link>
        </HStack>
      </DialogHeader>

      <VStack padding="xl" gap="xl">
        <Txt>Please review and select the most accurate address.</Txt>

        <VStack gap="md">
          <AddressRow
            onSelect={() => setSelectedAddress("default")}
            title="Address as entered"
            addressLine1={formattedDefaultAddress.addressLine1}
            addressLine2={formattedDefaultAddress.addressLine2}
            isSelected={selectedAddress === "default"}
          />
          <AddressRow
            onSelect={() => setSelectedAddress("suggested")}
            title="Suggested"
            addressLine1={formattedSuggestedAddress.addressLine1}
            addressLine2={formattedSuggestedAddress.addressLine2}
            isSelected={selectedAddress === "suggested"}
          />
        </VStack>
        <VStack padding={{ bottom: "sm" }} alignItems="center" gap="sm">
          <Button
            state={isLoading ? "loading" : "active"}
            onClick={async () => {
              setIsLoading(true)
              segmentAnalyticsLogger.logEvent(`Selected confirm address: ${selectedAddress}`)

              await onConfirmAddress(
                selectedAddress === "default" ? defaultAddress : suggestedAddress,
              )

              setIsLoading(false)
            }}
          >
            Confirm address
          </Button>
          <Link onClick={handleOnClose}>Edit</Link>
        </VStack>
      </VStack>
    </Dialog>
  )
}
